import { api, batchChangeStatus, getAffBillingReport } from 'api/billing/affiliate';
import Pagination from '@/components/pagination';
import GroupSelect from '@/components/GroupSelect';
import dateUtils from '@/utils/dateutils.js';
import qs from 'querystring';
import { filterObject } from 'utils/utils';
import { mapState, mapActions } from 'vuex';
export default {
  components: {
    Pagination,
    GroupSelect,
  },
  data() {
    return {
      pageSize: 20,
      currentPage: 1,
      affiliateChecked: true,
      sourceChecked: true,
      offerChecked: true,
      sourceOfferIdChecked: true,
      sourceOfferIdflag: false,
      packageChecked: false,
      listLoading: false,
      total: null,
      records: [],
      ids: [],
      affiliateIdArray: [],
      sourceIdArray: [],
      comfirmVisible: false,
      finiflag: false,
      statusOptions: [
        {
          label: 'None selected',
          value: '',
        },
        {
          label: 'UNKNOW',
          value: 'UNKNOW',
        },
        {
          label: 'DEDUCT',
          value: 'DEDUCT',
        },
        {
          label: 'FINI',
          value: 'FINI',
        },
        {
          label: 'PENDING',
          value: 'PENDING',
        },
      ],
      affiliateBilling: {
        affiliateIds: null,
        sourceIds: null,
        offerIds: null,
        sourceOfferIds: null,
        pkgs: null,
        fromDate: null,
        status: '',
        columns: [],
        groupColumns: [],
      },
      affiliateBillingList: [],
    };
  },
  watch: {
    listenChange: function() {
      console.log('###');
      this.$nextTick(() => {
        this.$refs.tableRef.doLayout();
      });
    },
  },
  computed: {
    ...mapState('affiliate', {
      affiliatesGroupList: (state) => state.affiliatesGroupList,
      affiliateLoading: (state) => state.affiliateLoading,
    }),
    ...mapState('source', {
      sourcesList: (state) => state.sourcesList,
      sourceLoading: (state) => state.sourceLoading,
    }),
    listenChange() {
      const {
        affiliateChecked,
        sourceChecked,
        offerChecked,
        sourceOfferIdChecked,
        sourceOfferIdflag,
        packageChecked,
      } = this;
      const columns = this.affiliateBilling.columns;
      return {
        affiliateChecked,
        sourceChecked,
        offerChecked,
        sourceOfferIdChecked,
        sourceOfferIdflag,
        packageChecked,
        columns,
      };
    },
  },
  mounted() {
    //默认选择
    const date = new Date();
    date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
    this.affiliateBilling.fromDate = dateUtils.date2StrYMD(date);

    this.getAffiliateList();
    this.getSourceIdsList();
  },
  methods: {
    ...mapActions('affiliate', ['getAffiliateList']),
    ...mapActions('source', ['getSourceIdsList']),
    handleSelectionChange(val) {
      this.records = val;
    },
    getAffiliateBillingParam() {
      let param = {
        page: this.currentPage,
        pagesize: this.pageSize,
      };
      const groupColumns = new Array();
      const columns = new Array();

      if (
        this.offerChecked == true &&
        columns.indexOf('offerId') == -1 &&
        columns.indexOf('name') == -1 &&
        groupColumns.indexOf('lpcd.offer_id') == -1
      ) {
        columns.push('offerId');
        columns.push('name');
        groupColumns.push('lpcd.offer_id');
      } else if (
        this.offerChecked == false &&
        columns.indexOf('offerId') !== -1 &&
        columns.indexOf('name') !== -1 &&
        groupColumns.indexOf('lpcd.offer_id') !== -1
      ) {
        const offerIdIndex = columns.findIndex((item) => {
          if (item == 'offer_id') {
            return true;
          }
        });
        const nameIndex = columns.findIndex((item) => {
          if (item == 'offerName') {
            return true;
          }
        });
        const lpcdofferIdIndex = groupColumns.findIndex((item) => {
          if (item == 'lpcd.offer_id') {
            return true;
          }
        });
        columns.splice(offerIdIndex, 1);
        columns.splice(nameIndex, 1);
        groupColumns.splice(lpcdofferIdIndex, 1);
      }

      if (
        this.offerChecked == true &&
        this.sourceOfferIdflag == true &&
        columns.indexOf('sourceOfferId') == -1
      ) {
        columns.push('sourceOfferId');
      } else if (
        this.offerChecked == false &&
        this.sourceOfferIdflag == false &&
        columns.indexOf('sourceOfferId') !== -1
      ) {
        const sourceOfferIdIndex = columns.findIndex((item) => {
          if (item == 'sourceOfferId') {
            return true;
          }
        });
        columns.splice(sourceOfferIdIndex, 1);
      }

      if (
        this.sourceChecked == true &&
        columns.indexOf('sourceCompany') == -1 &&
        groupColumns.indexOf('lpcd.source_id') == -1
      ) {
        columns.push('sourceCompany');
        groupColumns.push('lpcd.source_id');
      } else if (
        this.sourceChecked == false &&
        columns.indexOf('sourceCompany') !== -1 &&
        groupColumns.indexOf('lpcd.source_id') !== -1
      ) {
        const sourceCompanyIndex = columns.findIndex((item) => {
          if (item == 'sourceCompany') {
            return true;
          }
        });
        const lpcdsourceIdIndex = groupColumns.findIndex((item) => {
          if (item == 'lpcd.source_id') {
            return true;
          }
        });
        columns.splice(sourceCompanyIndex, 1);
        groupColumns.splice(lpcdsourceIdIndex, 1);
      }

      if (
        this.affiliateChecked == true &&
        columns.indexOf('affiliateCompany') == -1 &&
        groupColumns.indexOf('lpcd.affiliate_id') == -1
      ) {
        columns.push('affiliateCompany');
        groupColumns.push('lpcd.affiliate_id');
      } else if (
        this.affiliateChecked == false &&
        columns.indexOf('affiliateCompany') !== -1 &&
        groupColumns.indexOf('lpcd.affiliate_id') !== -1
      ) {
        const affiliateCompanyIndex = columns.findIndex((item) => {
          if (item == 'affiliateCompany') {
            return true;
          }
        });
        const lpcdaffiliateIdIndex = groupColumns.findIndex((item) => {
          if (item == 'lpcd.affiliate_id') {
            return true;
          }
        });
        columns.splice(affiliateCompanyIndex, 1);
        groupColumns.splice(lpcdaffiliateIdIndex, 1);
      }

      if (this.sourceOfferIdChecked == true && columns.indexOf('sourceOfferId') == -1) {
        columns.push('sourceOfferId');
      } else if (this.sourceOfferIdChecked == false && columns.indexOf('sourceOfferId') !== -1) {
        const sourceOfferIdIndex = columns.findIndex((item) => {
          if (item == 'sourceOfferId') {
            return true;
          }
        });
        columns.splice(sourceOfferIdIndex, 1);
      }

      if (this.packageChecked == true && columns.indexOf('prod') == -1) {
        columns.push('prod');
      } else if (this.packageChecked == false && columns.indexOf('prod') !== -1) {
        const prodIndex = columns.findIndex((item) => {
          if (item == 'prod') {
            return true;
          }
        });
        columns.splice(prodIndex, 1);
      }

      if (columns.length > 0) {
        columns.push('date');
        columns.push('conversions');
        columns.push('payout');
        columns.push('billStatus');
        columns.push('deductRevenue');
        columns.push('billRevenue');
        columns.push('description');
        columns.push('advDeduct');
        columns.push('advReason');
      }

      this.affiliateBilling.columns = columns;
      param = {
        ...this.affiliateBilling,
        ...param,
      };
      if (Array.isArray(param.sourceIds)) {
        param['sourceIds'] = param.sourceIds.join(',');
      }
      if (Array.isArray(param.affiliateIds)) {
        param['affiliateIds'] = param.affiliateIds.join(',');
      }
      if (Array.isArray(columns)) {
        param['columns'] = columns.join(',');
      }
      if (Array.isArray(groupColumns)) {
        param['groupColumns'] = groupColumns.join(',');
      }

      return param;
    },
    searchListClick(curPage) {
      this.listLoading = true;
      if (curPage) {
        this.currentPage = curPage;
      }
      let param = this.getAffiliateBillingParam();
      param = filterObject(param);
      getAffBillingReport(param).then((response) => {
        this.listLoading = false;

        if (response.code == 200) {
          this.ids = new Array();
          this.affiliateBillingList = response.result;

          this.total = response.total || 0;

          this.affiliateBillingList.forEach((item) => {
            this.ids.push(item.id);
          });
          this.sourceOfferIdflag = false;
        } else {
          this.affiliateBillingList = [];
          this.total = 0;
        }
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.searchListClick();
    },
    exportExcelFileClick() {
      if (this.affiliateIdArray != [] && this.affiliateIdArray.length > 0) {
        this.affiliateBilling.affiliateIds = this.affiliateIdArray;
      }

      if (this.sourceIdArray != [] && this.sourceIdArray.length > 0) {
        this.affiliateBilling.sourceIds = this.sourceIdArray;
      }

      let param = {};
      let columns = new Array();

      if (
        this.sourceChecked == true &&
        columns.indexOf('sourceCompany') == -1 &&
        columns.indexOf('sourceId') == -1
      ) {
        columns.push('sourceCompany');
        columns.push('sourceId');
      } else if (
        this.sourceChecked == false &&
        columns.indexOf('sourceCompany') !== -1 &&
        columns.indexOf('sourceId') !== -1
      ) {
        const sourceCompanyIndex = columns.findIndex((item) => {
          if (item == 'sourceCompany') {
            return true;
          }
        });
        const sourceIdIndex = columns.findIndex((item) => {
          if (item == 'sourceId') {
            return true;
          }
        });
        columns.splice(sourceCompanyIndex, 1);
        columns.splice(sourceIdIndex, 1);
      }

      if (
        this.affiliateChecked == true &&
        columns.indexOf('affiliateCompany') == -1 &&
        columns.indexOf('affiliateId') == -1
      ) {
        columns.push('affiliateCompany');
        columns.push('affiliateId');
      } else if (
        this.affiliateChecked == false &&
        columns.indexOf('affiliateCompany') !== -1 &&
        columns.indexOf('affiliateId') !== -1
      ) {
        const affiliateCompanyIndex = columns.findIndex((item) => {
          if (item == 'affiliateCompany') {
            return true;
          }
        });
        const affiliateIdIndex = columns.findIndex((item) => {
          if (item == 'affiliateId') {
            return true;
          }
        });
        columns.splice(affiliateCompanyIndex, 1);
        columns.splice(affiliateIdIndex, 1);
      }

      if (
        this.offerChecked == true &&
        columns.indexOf('offerId') == -1 &&
        columns.indexOf('name') == -1 &&
        columns.indexOf('sourceOfferId') == -1
      ) {
        columns.push('offerId');
        columns.push('name');
        columns.push('sourceOfferId');
      } else if (
        this.offerChecked == false &&
        columns.indexOf('offerId') !== -1 &&
        columns.indexOf('name') !== -1 &&
        columns.indexOf('sourceOfferId') !== -1
      ) {
        const offerIdIndex = columns.findIndex((item) => {
          if (item == 'offerId') {
            return true;
          }
        });
        const nameIndex = columns.findIndex((item) => {
          if (item == 'name') {
            return true;
          }
        });
        const sourceOfferIdIndex = columns.findIndex((item) => {
          if (item == 'sourceOfferId') {
            return true;
          }
        });
        columns.splice(offerIdIndex, 1);
        columns.splice(nameIndex, 1);
        columns.splice(sourceOfferIdIndex, 1);
      }

      if (this.packageChecked == true && columns.indexOf('prod') == -1) {
        columns.push('prod');
      } else if (this.packageChecked == false && columns.indexOf('prod') !== -1) {
        const prodIndex = columns.findIndex((item) => {
          if (item == 'prod') {
            return true;
          }
        });
        columns.splice(prodIndex, 1);
      }

      if (columns.length > 0) {
        columns.unshift('date');
        columns.push('conversions');
        columns.push('payout');
        columns.push('billStatus');
        columns.push('deductRevenue');
        columns.push('billRevenue');
        columns.push('description');
        columns.push('advDeduct');
        columns.push('advReason');
      }

      param['columns'] = columns.join(',');

      param['affiliateIds'] = this.affiliateBilling.affiliateIds.join(',');
      param['sourceIds'] = this.affiliateBilling.sourceIds.join(',');

      param['fromDate'] = this.affiliateBilling.fromDate;
      param['status'] = this.affiliateBilling.status;
      param = filterObject(param);

      const strParam = qs.stringify(param);
      window.location.href = `${api.DOWNLOAD_BILL}?${strParam}`;
    },
    batchFINIClick() {
      if (this.records.length == 0) {
        this.records = this.affiliateBillingList;
      }
      this.finiflag = true;
      this.comfirmVisible = true;
    },
    confirmFiniClick() {
      const recordArray = new Array();
      const length = this.records.length;
      for (let i = 0; i < length; i++) {
        recordArray.push(this.records[i].id);
      }

      let param = {};
      if (this.finiflag) {
        param['status'] = 'FINI';
      } else {
        param['status'] = 'PENDING';
      }
      param['ids'] = recordArray;
      param['fromDate'] = this.affiliateBilling.fromDate;
      batchChangeStatus(param).then((response) => {
        if (response.code === 200) {
          this.$message({
            message: 'batch update success',
            type: 'success',
          });
          this.sourceOfferIdflag = true;
          this.searchListClick();
        } else {
          this.$message({
            message: 'Error Update: ' + response.message,
            type: 'warning',
          });
        }
      });
      this.comfirmVisible = false;
      this.finiflag = false;
    },
    batchPENDINGClick() {
      if (this.records.length == 0) {
        this.records = this.affiliateBillingList;
      }
      this.comfirmVisible = true;
    },
  },
};
