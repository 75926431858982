<template>
  <div class="affiliateBilling">
    <!-- <div class="main-Title bgff"><h2>Affiliate Billing</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb10">
        <el-form v-model="affiliateBilling" label-width="100px" size="small">
          <el-row :gutter="10">
            <el-col :xs="20" :sm="23" :md="5" :lg="5" :xl="5">
              <el-form-item label="Affiliate(s)" label-width="80px">
                <group-select
                  v-model="affiliateBilling.affiliateIds"
                  :data-source="affiliatesGroupList"
                  multiple
                  collapse-tags
                  reserve-keyword
                  :loading="affiliateLoading"
                  clearable
                  filterable
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="4" :sm="1" :md="1" :lg="1" :xl="1" align="center">
              <el-form-item label-width="0px">
                <el-checkbox v-model="affiliateChecked"></el-checkbox>
              </el-form-item>
            </el-col>
            <el-col :xs="20" :sm="23" :md="5" :lg="5" :xl="5">
              <el-form-item label="Source(s)" label-width="70px">
                <group-select
                  size="small"
                  v-model="affiliateBilling.sourceIds"
                  :data-source="sourcesList"
                  multiple
                  collapse-tags
                  reserve-keyword
                  :loading="sourceLoading"
                  clearable
                  filterable
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="4" :sm="1" :md="1" :lg="1" :xl="1" align="center">
              <el-form-item label-width="0px">
                <el-checkbox v-model="sourceChecked"></el-checkbox>
              </el-form-item>
            </el-col>
            <el-col :xs="20" :sm="23" :md="5" :lg="5" :xl="5">
              <el-form-item label="OfferId(s)" label-width="80px">
                <el-input v-model="affiliateBilling.offerIds" placeholder="eg : 123,456"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="4" :sm="1" :md="1" :lg="1" :xl="1" align="center">
              <el-form-item label-width="0px">
                <el-checkbox v-model="offerChecked"></el-checkbox>
              </el-form-item>
            </el-col>
            <el-col :xs="20" :sm="23" :md="5" :lg="5" :xl="5">
              <el-form-item label="SourceOfferId">
                <el-input
                  v-model="affiliateBilling.sourceOfferIds"
                  placeholder="eg : 123,456"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="4" :sm="1" :md="1" :lg="1" :xl="1" align="center">
              <el-form-item label-width="0px">
                <el-checkbox v-model="sourceOfferIdChecked"></el-checkbox>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item label="STATUS" label-width="70px">
                <el-select v-model="affiliateBilling.status" class="w100">
                  <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item label="Date" label-width="60px">
                <el-date-picker
                  class="w100"
                  v-model="affiliateBilling.fromDate"
                  type="month"
                  format="yyyy - MM"
                  value-format="yyyy-MM"
                  placeholder="Select Month"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label-width="0px">
                <el-button type="primary" @click="searchListClick(1)">In Browser</el-button>
                <el-button type="primary" @click="exportExcelFileClick">Download Excel</el-button>
                <el-button type="success" @click="batchFINIClick">Batch FINI</el-button>
                <el-button type="warning" @click="batchPENDINGClick">Batch PENDING</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          class="w100"
          size="mini"
          v-loading="listLoading"
          stripe
          border
          ref="tableRef"
          :data="affiliateBillingList"
          highlight-current-row
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            key="selection"
            align="center"
          ></el-table-column>
          <el-table-column label="Date" prop="date" key="date" align="center"></el-table-column>
          <el-table-column
            label="SourceId"
            prop="sourceId"
            key="sourceId"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            label="SourceName"
            prop="sourceCompany"
            key="sourceCompany"
            min-width="120"
            align="center"
            v-if="sourceChecked"
          ></el-table-column>
          <el-table-column
            label="Affiliate"
            prop="affiliateCompany"
            key="affiliateCompany"
            show-overflow-tooltip
            min-width="100"
            align="center"
            v-if="affiliateChecked"
          ></el-table-column>
          <el-table-column
            label="OfferId"
            prop="offerId"
            min-width="100"
            align="center"
            key="offerId"
            v-if="offerChecked"
          ></el-table-column>
          <el-table-column
            label="SourceOfferId"
            prop="sourceOfferId"
            key="sourceOfferId"
            min-width="150"
            align="center"
            show-overflow-tooltip
            v-if="offerChecked"
          ></el-table-column>
          <el-table-column
            label="Name"
            prop="name"
            key="name"
            show-overflow-tooltip
            min-width="100"
            align="center"
            v-if="offerChecked"
          ></el-table-column>
          <el-table-column
            label="Porduct"
            prop="prod"
            key="prod"
            show-overflow-tooltip
            min-width="100"
            align="center"
            v-if="packageChecked"
          ></el-table-column>
          <template v-if="affiliateBilling.columns.length > 0">
            <el-table-column
              label="Conversions"
              prop="conversions"
              key="conversions"
              align="center"
              show-overflow-tooltip
              min-width="110"
            ></el-table-column>
            <el-table-column label="Payout" prop="payout"></el-table-column>
            <el-table-column
              label="BillStatus"
              prop="billStatus"
              key="billStatus"
              width="100"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              label="DeductRevenue"
              prop="deductRevenue"
              key="deductRevenue"
              min-width="130"
              align="center"
            ></el-table-column>
            <el-table-column
              label="BillRevenue"
              prop="billRevenue"
              key="billRevenue"
              min-width="110"
              align="center"
            ></el-table-column>
            <el-table-column
              label="Description"
              prop="sourceDesc"
              key="sourceDesc"
              min-width="100"
              align="center"
            ></el-table-column>
            <el-table-column
              label="AdvDeduct"
              prop="advDeduct"
              key="advDeduct"
              min-width="100"
              align="center"
            ></el-table-column>
            <el-table-column
              label="AdvReason"
              prop="advReason"
              key="advReason"
              min-width="100"
              align="center"
            ></el-table-column>
          </template>
        </el-table>
        <pagination
          class="block pagePanel"
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pageSize"
          :currentPage="currentPage"
        ></pagination>
      </el-card>
    </div>
    <!-- 询问框 -->
    <el-dialog title="Confirm" :visible.sync="comfirmVisible" width="40%">
      <span v-if="finiflag">Are you want to Change these Billing status to Fini？</span>
      <span v-else>Are you want to Change these Billing status to Pending？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="comfirmVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmFiniClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import AffiliateBillingCon from '../controllers/billing/AffiliateBilling';
  export default {
    name: 'AffiliateBilling',
    ...AffiliateBillingCon,
  };
</script>
<style lang="scss" scoped></style>
